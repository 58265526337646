@import "~leaflet/dist/leaflet.css";

.p-inputtext {
    border-radius: 7px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    background-color: #1A499020;
    outline-color: #1A4990;
    outline-width: 1px;
    color: #1A4990;
    font-size: 1.5rem;
    position:relative;
    top:-8px;
    font-family: "Inter-light", sans-serif;
}

.hero-menu-type {
    color: #1A4990;
    font-size: 1.5rem;
    font-family: "Inter-light", sans-serif;
}

.app-css {
    background-color: #D6F1FF !important;
}

.daily-forecast-container {
    color: #1A4990;
    font-family: "Inter-Medium", sans-serif;
    font-size: 1em;
}

.daily-forecast-container-day {
    color: #1A4990;
    font-family: "Inter-Medium", sans-serif;
    font-size: 1em;
    font-weight: bold;
}

.daily-forecast-container-temp {
    color: #1A4990;
    font-family: "Inter-Medium", sans-serif;
    font-size: 1.5em;
    margin-left: 5px
}

.hourly-forecast {
    min-width: 50vw;
    max-width: 80vw;
    height: 200px;
    font-family: "Inter-Medium", sans-serif;
}

.hourly-forecast-container {
    font-family: "Inter-medium", sans-serif;
    color: #1A4990;
}

.component-header {
    color: #1A4990;
    font-family: "Inter-medium", sans-serif;
    font-size: 0.8rem;
    margin-left: 3px;
    padding-bottom: 0.6em;
}

.language-display {
    color: #1A499085;
    font-family: "InterDisplay-Black", sans-serif;
    font-size: 3rem;
    margin-left: 3px;
    padding-bottom: 0.6em;
}

.react-horizontal-scrolling-menu--inner-wrapper {
    border-radius: 9px;
}

.leaflet-container {
    border-radius: 12px;
}

.hero-title {
    color: #1A4990;
    font-family: "InterDisplay-BlackItalic", sans-serif;
    font-size: 5rem;
    margin-bottom: 1%;
    margin-top: -2%;
}

@media (max-width: 768px) {
    body {
        font-size: 12px !important;
    }
    .language-display {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    body {
        /*font-size: 11px !important;*/
    }
    .language-display {
        color: #1A499085;
        font-family: "InterDisplay-Black", sans-serif;
        font-size: 1.5rem;
        margin-left: 3px;
        padding-bottom: 0.6em;
    }
    .daily-forecast-container-day {
        color: #1A4990;
        font-family: "Inter", sans-serif;
        font-size: 1em;
        font-weight: normal;
    }
}



